import { HighchartsWithAccessibility, sharedChartOptions } from "../helpers";
import HighchartsReact from "highcharts-react-official";
import { memo, useRef, useEffect } from "react";
import { CHART_DATA_LIMIT } from "../../../Constants/constants";
import { Loading } from "../../LoadingSpinner/Loading";
import { ChartErrorState } from "../ChartErrorState/ChartErrorState";
import ChartWarning from "../ChartWarning/ChartWarning";
import { ChartConfig } from "../chart.i";
import { useLingui } from "@lingui/react";
import { msg } from "@lingui/macro";

interface DataRow {
  [key: string]: any;
}

function extractPieData(data: DataRow[], chartConfig: ChartConfig) {
  const categoricalColumn = chartConfig.columns.find(
    (key) => chartConfig.column_types[key] === "categorical"
  );
  const numericalColumn = chartConfig.columns.find(
    (key) => chartConfig.column_types[key] === "numerical"
  );

  const aggregateData = data.reduce((acc, item) => {
    const category = item[categoricalColumn];
    if (!acc[category]) {
      acc[category] = 0;
    }
    acc[category] += item[numericalColumn];
    return acc;
  }, {});

  const seriesData: HighchartsWithAccessibility.PointOptionsObject[] = Object.entries(
    aggregateData
  ).map(([name, y]) => ({
    name,
    y,
  }));

  return seriesData;
}

const PieChartH = ({
  data,
  height,
  width,
  chartConfig,
  loading,
  error,
  totalRows,
  title,
  onChartInstance,
}) => {
  const { _ } = useLingui();
  const chartRef = useRef<HighchartsReact.RefObject>(null);

  useEffect(() => {
    if (chartRef.current && chartRef.current.chart) {
      const cleanup = onChartInstance(chartRef.current.chart);
      return cleanup;
    }
  }, [onChartInstance]);

  if (loading || !chartConfig || !chartConfig.columns || !data) {
    return <Loading />;
  }
  if (error) {
    return <ChartErrorState />;
  }

  const seriesData = extractPieData(data, chartConfig);
  const options: HighchartsWithAccessibility.Options = {
    ...sharedChartOptions,
    chart: {
      ...sharedChartOptions.chart,
      type: "pie" as const,
      height,
      width,
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
    },
    title: {
      text: "",
    },
    tooltip: {
      // eslint-disable-next-line lingui/no-unlocalized-strings
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
      enabled: chartConfig.tooltip ?? true,
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          // eslint-disable-next-line lingui/no-unlocalized-strings
          format: "<b>{point.name}</b>: {point.percentage:.1f} %",
        },
        showInLegend: true,
      },
    },
    series: [
      {
        type: "pie" as const,
        name: _(msg`Share`),
        data: seriesData,
      },
    ],
    credits: {
      enabled: false,
    },
    legend: {
      layout: chartConfig.legend_layout || "vertical",
      align: "right",
      verticalAlign: chartConfig.legend_vertical_align || "middle",
      floating: chartConfig.legend_floating ?? false,
      labelFormatter: function () {
        const point = this as HighchartsWithAccessibility.Point;
        return `${point.name}: ${point.y?.toFixed(1)}`;
      },
    },
    responsive: {
      rules: [
        {
          // hide legend when width is less than 500px
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              enabled: false,
            },
          },
        },
      ],
    },
  };

  return (
    <div>
      <HighchartsReact highcharts={HighchartsWithAccessibility} options={options} ref={chartRef} />
      {totalRows > CHART_DATA_LIMIT && <ChartWarning />}
    </div>
  );
};

const MemoizedPieChartH = memo(PieChartH, (prevProps, nextProps) => {
  return (
    prevProps.data === nextProps.data &&
    prevProps.height === nextProps.height &&
    prevProps.width === nextProps.width &&
    prevProps.chartConfig === nextProps.chartConfig &&
    prevProps.totalRows === nextProps.totalRows &&
    prevProps.title === nextProps.title &&
    prevProps.onChartInstance === nextProps.onChartInstance
  );
});

export default MemoizedPieChartH;
