import React, { useMemo, useCallback } from "react";
import { Chart as HighchartsChart } from "highcharts";
import { useResize } from "../../../hooks/useResize";
import { ChartType } from "../../types";
import MemoizedBarChartH from "../../../components/Charts/BarChart/BarChartH";
import MemoizedLineChartH from "../../../components/Charts/LineChart/LineChartH";
import MemoizedPieChartH from "../../../components/Charts/PieChart/PieChartH";
import MemoizedScatterChartH from "../../../components/Charts/ScatterChart/ScatterChartH";
import { ChartProps } from "../ChartBuilder/ChartBuilder";
import { useChart } from "../../../context/chart";
import { ChartContextI } from "../../../context/chart/chart.i";

const ChartRenderer: React.FC<{ chartProps: ChartProps }> = ({ chartProps }) => {
  const { componentRef, width, height } = useResize();
  const { setChartInstances } = useChart();

  const handleChartInstance = useCallback(
    (chart: HighchartsChart | null) => {
      if (chart) {
        setChartInstances((prev: ChartContextI["chartInstances"]) => [
          ...prev,
          { id: chartProps.docId, chart: chart },
        ]);
      }
      return () => {
        setChartInstances((prev: ChartContextI["chartInstances"]) =>
          prev.filter((c) => c.id !== chartProps.docId)
        );
      };
    },
    [setChartInstances]
  );

  const renderChart = useMemo(() => {
    if (width === 0 || height === 0) return null;

    const props = {
      ...chartProps,
      chartConfig: {
        ...chartProps.chartConfig,
        columns: Object.keys(chartProps.selectedColumns),
      },
      width,
      height,
      onChartInstance: handleChartInstance,
    };

    switch (chartProps.chartType) {
      case ChartType.BAR_CHART:
        return <MemoizedBarChartH {...props} />;
      case ChartType.LINE_CHART:
        return <MemoizedLineChartH {...props} />;
      case ChartType.PIE_CHART:
        return <MemoizedPieChartH {...props} />;
      case ChartType.SCATTER_CHART:
        return <MemoizedScatterChartH {...props} />;
      default:
        return null;
    }
  }, [chartProps, width, height, handleChartInstance]);

  return (
    <div ref={componentRef} className="flex w-full flex-grow flex-col overflow-hidden border-none">
      <div className="chart-renderer self-center">{renderChart}</div>
    </div>
  );
};

export { ChartRenderer };
